import React from 'react';
import './App.css';
import Navigationon from './components/Navigation';
import { CardDeck, Card, Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';


function App() {
  return (
    <div className="App">
      <Navigationon />
      <header className="App-header">
        <h1 className="main-slogan">YARINLARA HAZIR MISINIZ?</h1>
        <p>Geleceginizi guven altina almak icin</p>
      </header>
      <main>
        <div className="text-center my-5">
          <h2 className="display-5">Urunlerimiz</h2>
        </div>
        <Container>
          <Row>
            <Col>
              <CardDeck>
                <Card>
                  <Card.Img variant="top" src="/images/car.jpg" />
                  <Card.Body>
                    <Card.Title>Kasko ve Tasit Sigortasi</Card.Title>
                    <Card.Text>
                      30 yillik tecrube ve bilgi birikimimiz ile siz degerli musterilerimizin tasit ve kasko sigortalarini en uygun fiyat garantisi ile sunmaktayiz. 
                      Kaza aninda ve sonrasinda tecrubeli ekibimiz size yardimci olmaya her an hazir.
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Img variant="top" src="/images/health.jpg" />
                  <Card.Body>
                    <Card.Title>Saglik Sigortasi</Card.Title>
                    <Card.Text>
                      Sizin ve sevdiklerinizin sagligi bizim icin degerli. Size ve ailenize en uygun saglik sigortasi urunlerimizden ucretsiz bilgi almak ve danismak icin bizimle hemen iletisime gecin.
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Img variant="top" src="/images/dask.jpg" />
                  <Card.Body>
                    <Card.Title>DASK</Card.Title>
                    <Card.Text>
                    Zorunlu Deprem Sigortası evinizi ve ailenizi güvence altına alır. Dask poliçenizi yaptırmak, prim tutarını hesaplamak ve ürün genel şartların ogrenmek icin bizimle hemen iletiisime gecin.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </CardDeck>
            </Col>
          </Row>
        </Container>
        <Container className="my-5">
          <Row>
            <Col>
              <h3 className="pb-3">Follow Us</h3>
              <h5 className="p-2"><FaFacebook color="#3b5998"/> Facebook</h5>
              <h5 className="p-2"><FaTwitter color="#00acee"/> Twitter</h5>
              <h5 className="p-2"><FaInstagram color="#3f729b"/> Instagram</h5>
            </Col>
            <Col>
              <h3 className="pb-3">Contacts</h3>
              <p><strong>Adres:</strong> Tantavi, Su İş Cd. No:27, 34764 Ümraniye/İstanbul</p>
              <p><strong>Ofis Telefon:</strong> +90 216 523 40 66</p>
              <p><strong>Mobil Telefon:</strong> +90 546 678 42 30</p>
              <p><strong>Email:</strong> turan@turanyilmazsigorta.com</p>
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
}

export default App;
